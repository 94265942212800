.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  z-index: 1001;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
}
@media (max-width: 576px) {
  .ass-team-modal {
    width: 90vw;
    height: 90vh;
  }
}

.ass-team-modal {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray */
}

.modal-content {
  background-color: #fff; /* White background for modal content */
}
