/*.image {
    width: 100%;
    height: auto;
  }
  
@media (min-width: 768px) {
    .image {
      width: 50%;
    }
  }
  
@media (min-width: 1024px) {
    .image {
      width: 25%;
    }
 */
.carousel-image {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .carousel-caption {
    bottom: 20px;
  }
}

@media (max-width: 767px) {
  .carousel-caption {
    bottom: 10px;
  }

  .carousel-caption h5 {
    font-size: 1.2rem;
  }

  .carousel-caption p {
    font-size: 0.9rem;
  }
}

/*.carousel-image-container {
  width: 100%;
  height: 600px; 
  overflow: hidden; 
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

@media (min-width: 768px) {
  .carousel-caption {
      bottom: 20px;
  }
}

@media (max-width: 767px) {
  .carousel-caption {
      bottom: 10px;
  }
  
  .carousel-caption h5 {
      font-size: 1.2rem;
  }
  
  .carousel-caption p {
      font-size: 0.9rem;
  }
}*/
